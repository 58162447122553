import { ref, watch } from '@vue/composition-api'
import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import { DEFAULT_PER_PAGE, PER_PAGE_OPTIONS } from '@/constants/base'

export default function useClassDetails() {
  // Use toast
  const toast = useToast()

  const refClassStudentsTable = ref(null)
  const currentClassId = router.currentRoute.params.id
  const currentSubjectId = router.currentRoute.params.subjectId
  // Table Handlers
  const tableColumns = [
    { key: 'user_name', label: 'Student Name', sortable: true },
    { key: 'actions', class: 'table-actions' },
  ]

  const classDetails = ref({
    name: '',
  })
  const isLoading = ref(false)
  const perPage = ref(DEFAULT_PER_PAGE)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = PER_PAGE_OPTIONS
  const searchQuery = ref('')
  const sortBy = ref('user_name')
  const isSortDirDesc = ref(false)

  const dataMeta = ref({
    from: 0,
    to: 0,
    of: 0,
  })

  const refetchData = () => {
    refClassStudentsTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchStudentsList = (ctx, callback) => {
    const queryParams = {
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      pageType: 'group',
      groupId: currentClassId,
    }
    if (searchQuery.value) {
      queryParams.userName = searchQuery.value
    }

    axios.get('/auth/applications', {
      params: queryParams,
    })
      .then(response => {
        const { data } = response.data
        const { total, from, to } = response.data.meta

        callback(data)
        totalItems.value = total
        dataMeta.value = {
          from,
          to,
          of: total,
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching students' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchClassDetails = () => {
    isLoading.value = true
    return axios
      .get(`/auth/academics/view/${currentClassId}`)
      .then(response => response.data.data)
      .catch(() => toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching Class item',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      }))
      .finally(() => {
        isLoading.value = false
      })
  }

  const removeStudentFromClass = queryParams => axios
    .post(`/auth/groups/${queryParams.group_id}/remove-applications`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Student was successfully removed from the class',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error removing Student',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchStudentsList,
    removeStudentFromClass,
    tableColumns,
    isLoading,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    totalItems,
    isSortDirDesc,
    refClassStudentsTable,

    refetchData,
    fetchClassDetails,
    classDetails,
    currentClassId,
    currentSubjectId,
  }
}
