<template>
  <div>
    <h2 class="mb-2">
      Subjects
    </h2>

    <b-overlay
      id="overlay-background"
      :show="isLoading"
      variant="white"
      opacity="1"
      rounded="sm"
    >
      <b-card>
        <h3>
          {{ classDetails.name }} <span class="text-muted">({{ classDetails.students_amount }} {{ classDetails.students_amount == 1 ? camperStudent(1) : camperStudent(2) }})</span>
        </h3>
        <b-card
          no-body
          bg-variant="light"
          class="border-light"
        >
          <b-card-body>
            <h4 class="d-flex align-items-center justify-content-between w-100 pb-1 border-bottom">
              <span>
                Subjects <span class="text-muted">({{ classDetails.subjects_count }})</span>
              </span>

              <!-- Dropdown -->
              <b-dropdown
                variant="link"
                toggle-class="mx-auto p-0"
                no-caret
                right
              >

                <template #button-content>
                  <feather-icon
                    icon="MoreHorizontalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item
                  v-if="$can('create-subject', permissionSubjects.Academics)"
                  v-b-modal.add-subject-modal
                >
                  <span>Add Subject</span>
                </b-dropdown-item>

              </b-dropdown>
            </h4>
            <b-row>
              <b-col
                v-for="subject in classDetails.subjects"
                :key="subject.id"
                sm="12"
                md="4"
              >
                <b-link
                  :to="{ name: 'admin-subject-details', params: { id: classDetails.id, subjectId: subject.id } }"
                >
                  <b-card
                    class="subject-item"
                  >
                    <b-col
                      cols="12"
                      class="subject-card"
                    >
                      <div>
                        <h5>{{ subject.name }}</h5>
                        <h6>Teacher: {{ subject.teacher_name }}</h6>
                      </div>
                      <h5 class="text-muted">
                        ({{ classDetails.students_amount }} {{ classDetails.students_amount == 1 ? camperStudent(1) : camperStudent(2) }})
                      </h5>
                    </b-col>
                  </b-card>
                </b-link>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-card>
    </b-overlay>

    <!-- Table Container Card -->
    <students-list
      @refetchData="prepareClassDetails"
    />

    <add-subject-modal
      v-if="$can('create-subject', permissionSubjects.Academics)"
      :selected-class-id="currentClassId"
      @success="prepareClassDetails"
    />
  </div>

</template>

<script>
import {
  BCard, BCardBody, BRow, BCol, BOverlay,
  BDropdown, BDropdownItem, BLink,
} from 'bootstrap-vue'

import useClassDetails from './useClassDetails'
import AddSubjectModal from './modals/AddSubjectModal.vue'
import StudentsList from './StudentsList.vue'
import { permissionSubjects } from '@/libs/acl/constants'

export default {
  name: 'ClassDetails',
  components: {
    BOverlay,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BLink,

    AddSubjectModal,
    StudentsList,
  },
  setup() {
    const {
      fetchStudentsList,
      tableColumns,
      isLoading,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      totalItems,
      isSortDirDesc,
      refClassStudentsTable,

      refetchData,
      fetchClassDetails,
      classDetails,
      currentClassId,
    } = useClassDetails()

    return {
      fetchStudentsList,
      tableColumns,
      isLoading,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      totalItems,
      isSortDirDesc,
      refClassStudentsTable,

      refetchData,
      fetchClassDetails,
      classDetails,
      currentClassId,
    }
  },
  async created() {
    this.prepareClassDetails()
  },
  data() {
    return {
      permissionSubjects,
    }
  },
  methods: {
    async prepareClassDetails() {
      this.classDetails = await this.fetchClassDetails()
    },
  },
}
</script>`

<style lang="scss" scoped>
  .subject-item {
    box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.12) !important;
    border-radius: 8px;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .subject-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media (max-width: 769px) {
    .subject-card {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: start;
    }
  }
</style>
