<template>
  <ValidationObserver
    ref="addSubjectModal"
  >
    <b-modal
      id="add-subject-modal"
      ref="add-subject-modal"
      title="Add Subject"
      centered
      no-close-on-backdrop
      ok-title="Add Subject"
      :ok-disabled="isLoading"
      cancel-variant="outline-secondary"
      size="sm"
      class="p-0"
      @ok="handleForm"
      @hidden="resetModal"
    >
      <b-overlay
        id="overlay-background"
        :show="isLoading"
        variant="white"
        opacity="1"
        rounded="sm"
      >
        <b-form-group
          label="Subject"
          class="mb-2"
        >
          <validation-provider
            #default="{ errors }"
            name="Subject"
            rules="required"
          >
            <v-select
              ref="subjectsSelect"
              v-model="subject"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="subjectsList"
              label="name"
              value="id"
              placeholder="Select Subjects"
              :reduce="item => item.id"
              @close="createNewSubject = false"
            >
              <template #option="{ name }">
                <span>{{ name }}</span>
              </template>
              <template #list-footer>
                <li
                  v-if="!createNewSubject"
                  class="mx-1 py-1 border-top border-top-secondary"
                >
                  <b-button
                    class="w-100"
                    variant="outline-primary"
                    @click="createNewSubject = true"
                  >
                    <span class="ml-25 align-middle">
                      Add New
                      <feather-icon
                        icon="PlusIcon"
                        size="18"
                        class="ml-1"
                      />
                    </span>
                  </b-button>
                </li>
                <li
                  v-else
                  class="mx-1 py-1 border-top border-top-secondary"
                >
                  <b-form-group
                    label="Name"
                  >
                    <b-form-input
                      ref="footerInput"
                      v-model="newSubjectName"
                      type="text"
                      placeholder="New Subject"
                      @click="handleInputFocus('subjectsSelect')"
                      @keyup.enter="addNewSubject"
                    />
                  </b-form-group>
                  <div
                    class="w-100 text-primary text-center font-weight-bold cursor-pointer"
                    @click="addNewSubject"
                  >
                    <span class="ml-25 align-middle">
                      Apply
                      <feather-icon
                        icon="CheckIcon"
                        size="18"
                        class="ml-1"
                      />
                    </span>
                  </div>
                </li>
              </template>
            </v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label="Teacher"
          class="mb-2"
        >
          <v-select
            v-model="teacher"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="teachersList"
            :clearable="false"
            :reduce="item => item.id"
            placeholder="Select Teacher"
          />
        </b-form-group>
      </b-overlay>
    </b-modal>
  </ValidationObserver>
</template>

<script>
import {
  BModal,
  BOverlay,
  BFormGroup,
  BFormInput,
  BButton,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import useClassesList from '@/views/admin/academics/classes-list/useClassesList'

export default {
  name: 'AddSubjectModal',
  components: {
    BModal,
    BOverlay,
    BFormGroup,
    BFormInput,
    BButton,

    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  props: {
    selectedClassId: {
      type: String || null,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      teacher: null,
      subject: null,
      teachersList: [],
      subjectsList: [],
      createNewSubject: false,
      newSubjectName: '',
      required,
    }
  },
  computed: {
    programId() {
      return this.$store.getters['verticalMenu/getDefaultProgram']
    },
  },
  setup() {
    const {
      fetchSubjects,
      fetchTeachersList,
      addSubjectToClass,
      createSubject,
    } = useClassesList()

    return {
      fetchSubjects,
      fetchTeachersList,
      addSubjectToClass,
      createSubject,
    }
  },
  created() {
    this.prepareSubjectsList()
    this.prepareTeachersList()
  },
  methods: {
    async prepareTeachersList() {
      const queryParams = {
        program_id: this.programId,
      }
      await this.fetchTeachersList(queryParams)
        .then(response => {
          this.teachersList = response.map(item => ({ id: item.user.id, label: item.user.full_name }))
        })
    },
    async prepareSubjectsList() {
      const queryParams = {
        program_id: this.programId,
      }
      this.subjectsList = await this.fetchSubjects(queryParams)
    },
    handleInputFocus(refId) {
      // Focus footerInput manually
      this.$refs.footerInput.focus()
      // Keep the dropdown open
      this.$refs[refId].open = true
    },
    async addNewSubject() {
      // eslint-disable-next-line no-param-reassign
      if (this.newSubjectName) {
        const data = {
          name: this.newSubjectName,
          program_id: this.programId,
        }
        this.isLoading = true
        await this.createSubject(data)
          .then(response => {
            if (response.id) {
              this.subjectsList.push(response)
              this.subject = response.id
              this.createNewSubject = false
              this.newSubjectName = ''
            }
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    handleForm(bvModalEvt) {
      bvModalEvt.preventDefault()

      return new Promise((resolve, reject) => {
        this.$refs.addSubjectModal.validate().then(success => {
          if (success) {
            resolve(true)
            this.handleAddSubject()
          } else {
            reject()
          }
        })
      })
    },
    async handleAddSubject() {
      this.isLoading = true
      const queryParams = {
        type: 'add',
        group_id: this.selectedClassId,
        teacher_id: this.teacher,
        subject_id: this.subject,
      }

      await this.addSubjectToClass(queryParams)
        .then(() => {
          this.$emit('success')
        })
        .finally(() => {
          this.isLoading = false
          this.$bvModal.hide('add-subject-modal')
        })
    },
    resetModal() {
      this.teacher = null
      this.subject = null
    },
  },
}
</script>

<style>

</style>
